import React from "react";
import "url-search-params-polyfill";
import { USER_TIERS, SPIN_OFF } from "./constants";
import formatter from "./formatUtils";

// do not remove this. it is needed for MSORSHELL
// eslint-disable-next-line no-unused-vars
import * as MSORVendor from "utils/vendor/mso-integrations-vendor-mod"; // NOSONAR

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";

const parseXML = (xml) => {
	try {
		const parser = new DOMParser();
		const serializer = new XMLSerializer();
		const xmlDoc = parser.parseFromString(xml, "application/xml");
		let text = xmlDoc.getElementsByTagName("text")[0];
		//change to text block
		text = serializer.serializeToString(text);
		//include Markit specific tags, replace markit items
		text = text.replace(/<text>/g, "<div>");
		text = text.replace(/<\/text>/g, "</div>");
		return text;
	} catch {
		return "no text node";
	}
};

const parseXMLNewsWireBody = (xml) => {
	try {
		const parser = new DOMParser();
		const serializer = new XMLSerializer();
		const xmlDoc = parser.parseFromString(xml, "application/xml");
		let text = xmlDoc.getElementsByTagName("BODY")[0];
		//change to text block
		text = serializer.serializeToString(text);
		//include Markit specific tags, replace markit items
		text = text.replace(/(\r\n|\r|\n)/g, "<br>");
		return text;
	} catch {
		return "no text node";
	}
};

const getObjectPropertyValue = function (obj, propertyNames) {
	let currentObj;
	if (!obj || !propertyNames) {
		return currentObj;
	}
	let props = [];
	if (typeof propertyNames === "string") {
		props = propertyNames.split(".");
	}

	if (Array.isArray(propertyNames)) {
		props = propertyNames;
	}

	currentObj = { ...obj };
	let found = false;
	const propertySize = props.length - 1;
	props.forEach((prop, index) => {
		if (currentObj.hasOwnProperty(prop)) {
			currentObj = currentObj[prop];
			found = propertySize === index;
		}
	});

	return found ? currentObj : undefined;
};

const fetchToken = async () => {
	const query = new URLSearchParams(window.location.search);
	const symbol = query.get("symbol");
	const viewType = query.get("viewType") ? query.get("viewType") : "desktop";

	const msParentDomain = window.MD?.PARENT_DOMAIN
		? window.MD.PARENT_DOMAIN
		: document.referrer;

	const env = query.get("environment") ? query.get("environment") : msParentDomain;
	const mspi = query.get("mspi");
	const baseUrl = query.get("baseURL");

	//mspi = 1 implies that we have already kicked off SP once before. Only retrying once.
	if (mspi === "1" || !symbol || !viewType || !env) {
		console.log("not fetching token - missing params.");
		return;
	}

	if (!mspi) {
		// set the correct domains and paths
		const domainOrigin = window.location.origin;

		const refPickupHost = window.MD.MSO_DOMAIN
			? window.MD.MSO_DOMAIN.replace("https://", "")
			: "";
		const targetDomainPath = domainOrigin + window.location.pathname;

		// grab and format existing query string parameters
		const existingParams = window.location.search;
		const formattedParams = existingParams.replace(/^\?/, "");
		const targetParams = baseUrl
			? `${formattedParams}&baseURL=${baseUrl}`
			: formattedParams;

		// encode the url to redirect to after a successful saml request
		const encodedTarget = encodeURIComponent(
			`${targetDomainPath}?${targetParams}&adapterId=SpRefAdapter&refPickupHost=${refPickupHost}/ms-research/api&mspi=1`
		);

		/*
		 Redirect to MSO saml request controller in order to generate an auth token
		 we need to access MSO because the saml request should be server-to-server and not browser-to-server
		 If successful, redirect to the correct MS Research page via the TargetSource query param in the URL
		*/
		const url = `${domainOrigin}/MSOnline/samlrequest?environment=${env}&TargetResource=${encodedTarget}&isFromRedesign=true`;
		window.location.href = url;
	}
};

const cloneObject = (obj) => {
	if (!obj || typeof obj !== "object") {
		return undefined;
	}
	//get list of object keys
	//add key to new object
	//check if key value is an object - if so recursive call
	const clonedObj = {};
	const innerClone = (input) => {
		if (!input) {
			return undefined;
		}

		if (typeof input !== "object") {
			return input;
		}

		const objKeys = Object.keys(input);
		for (const key of objKeys) {
			clonedObj[key] = input[key];
			if (typeof input[key] === "object") {
				innerClone(input[key]);
			}
		}
	};

	innerClone(obj);

	return clonedObj;
};

const truncateText = (text, limit) => {
	if (!text) {
		return "";
	}

	if (typeof text === "string" && limit && limit > 0 && text.length > limit) {
		return text.substring(0, limit - 3) + "...";
	}

	return text;
};

export const getCookieValue = (name) => {
	if (!name) {
		return null;
	}
	const cookieArr = document.cookie.split(";");
	let value = null;
	for (const item of cookieArr) {
		var cookieMap = item.split("=");
		if (name === cookieMap[0].trim()) {
			value = decodeURIComponent(cookieMap[1].trim());
			break;
		}
	}

	return value;
};

// Calculate when Good Friday is for the given year, as it is an NYSE holiday.
// Grabbed from Stack Overflow answer based off a known algorthm for calculating Easter:
// https://stackoverflow.com/questions/1284314/easter-date-in-javascri
// https://en.wikipedia.org/wiki/Computus
// Unit tests added in this project to validate the algorithm
const getGoodFridayDateFromYear = (y) => {
	var date, a, b, c, m, d;
	// Instantiate the date object.
	date = new Date();

	// Set the timestamp to midnight.
	date.setHours(0, 0, 0, 0);

	// Set the year.
	date.setFullYear(y);

	// Find the golden number.
	a = y % 19;

	// Choose which version of the algorithm to use based on the given year.
	b = 2200 <= y && y <= 2299 ? (11 * a + 4) % 30 : (11 * a + 5) % 30;

	// Determine whether or not to compensate for the previous step.
	c = b === 0 || (b === 1 && a > 10) ? b + 1 : b;

	// Use c first to find the month: April or March.
	m = 1 <= c && c <= 19 ? 3 : 2;

	// Then use c to find the full moon after the northward equinox.
	d = (50 - c) % 31;

	// Mark the date of that full moon—the "Paschal" full moon.
	date.setMonth(m, d);

	// Gregorian Western Easter Sunday
	// Count forward the number of days until the following Sunday (Easter).
	date.setMonth(m, d + (7 - date.getDay()));

	// Go back 2 days to find Good Friday
	date.setDate(date.getDate() - 2);

	return date;
};

// Make sure to check the previous Friday or the next Monday if a fixed date holiday falls on a weekend
const isDateOrDateObserved = (dateToCheck, month, date) => {
	// check the fixed holiday date for the current year unless it is New Years Eve (check New Years Day of the next year)
	const dateToCheckYear =
		dateToCheck.getMonth() === 11 && dateToCheck.getDate() === 31
			? dateToCheck.getFullYear() + 1
			: dateToCheck.getFullYear();

	// construct the moment object for the holiday on the month and date passed in
	let holidayDateObserved = formatter.moment(`${dateToCheckYear}-${month}-${date}`);
	const holidayDayOfWeek = holidayDateObserved.day();

	// if holiday falls on saturday
	if (holidayDayOfWeek === 6) {
		holidayDateObserved.subtract(1, "day");
		// if holiday falls on sunday
	} else if (holidayDayOfWeek === 0) {
		holidayDateObserved.add(1, "day");
	}

	return (
		dateToCheck.getMonth() === holidayDateObserved.month() &&
		dateToCheck.getDate() === holidayDateObserved.date()
	);
};

// For All NYSE Markets
// www.nyse.com/markets/hours-calendars
const isBankHoliday = (date) => {
	if (isDateOrDateObserved(date, "01", "01")) {
		return "New Year";
	} else if (isDateOrDateObserved(date, "07", "04")) {
		return "Independence Day";
	}
	//else if (isDateOrDateObserved(date, '11', '11')) { return "Veterans Day"; }
	else if (isDateOrDateObserved(date, "12", "25")) {
		return "Christmas Day";
	}

	// dynamic holidays
	const isDay = (d, month, day, occurance) => {
		if (d.getMonth() === month - 1 && d.getDay() === day) {
			if (occurance > 0) {
				return occurance === Math.ceil(d.getDate() / 7);
			} else {
				// check last occurance
				let _d = new Date(d);
				_d.setDate(d.getDate() + 7);
				return _d.getMonth() > d.getMonth();
			}
		}
		return false;
	};

	if (isDay(date, 1, 1, 3)) {
		return "MLK Day";
	} else if (isDay(date, 2, 1, 3)) {
		return "Presidents Day";
	} else if (date.getTime() === getGoodFridayDateFromYear(date.getFullYear()).getTime()) {
		return "Good Friday";
	} else if (isDay(date, 5, 1, -1)) {
		return "Memorial Day";
	} else if (isDay(date, 9, 1, 1)) {
		return "Labor Day";
	}
	//else if (isDay(date, 10, 1, 2)) { return "Columbus Day"; }
	else if (isDay(date, 11, 4, 4)) {
		return "Thanksgiving Day";
	}

	// Non Business days
	if (date.getDay() === 0) {
		return "Sunday";
	} else if (date.getDay() === 6) {
		return "Saturday";
	}

	// not a holiday
	return "";
};

// returns the number of business days (i.e. exluding weekends and NYSE holidays) between the passed-in dates
const numBusinessDaysBetweenDates = (minDate, maxDate) => {
	if (!minDate || !maxDate) {
		return null;
	}

	// start at first input date
	let dateToCheckStartOf = minDate.startOf("day");
	let maxDateStartOf = maxDate.startOf("day");

	const maxDaysBetween = maxDateStartOf.diff(dateToCheckStartOf, "days");

	if (maxDaysBetween < 0) {
		return null;
	}

	let numDaysBetween = 0;

	// find date max business days in the future, accounting for weekends and NYSE bank holidays
	while (!dateToCheckStartOf.isSame(maxDateStartOf) && numDaysBetween < maxDaysBetween) {
		dateToCheckStartOf = dateToCheckStartOf.add(1, "day");

		if (!isBankHoliday(dateToCheckStartOf.toDate())) {
			numDaysBetween++;
		}
	}

	return numDaysBetween;
};

/**
 * Utility for updating the parent frame with a post message about the our iframe height and width
 * @param {Number} frameHeight - the height of the iframe
 * @param {Number} frameWidth - the width of the iframe
 * @param {Number} heightIncrement - for desktop we add an extra 20px to the bottom as a buffer. For mobile we use 40px.
 */
const postDimensions = (frameHeight, frameWidth, heightIncrement = 75) => {
	if (frameHeight || frameWidth) {
		const framedLoadedPayload = {
			name: "MDFrameResized",
			height: frameHeight + heightIncrement,
			width: frameWidth,
		};
		const postMessage = JSON.stringify(framedLoadedPayload);
		window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN); // NOSONAR

		// Need to catch cross-origin frame error when in non-integrated env
		try {
			if (
				window?.MD?.INTEGRATED_DOMAINS?.includes(window?.MD?.PARENT_DOMAIN) ||
				IsIntegratedEnv()
			) {
				const postMessage = {
					name: "ResizeIframe",
					data: {
						Height: `${frameHeight + heightIncrement}px`,
						Width: `${frameWidth}px`,
					},
				};
				window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN);
			} else {
				if (window.console) {
					console.info("Works only in integrated environments!");
				}
			}
		} catch (e) {
			if (window.console) {
				console.log(e);
				console.info("Works only in integrated environments!");
			}
		}
	}
};

/**
 * Utility for sending a post message to the parent frame to have the parent scroll by a certain amount.
 * @param {Number} xScrollValue - negative values scroll left and positive values scroll right that amount.
 * @param {Number} yScrollValue - negative values scroll up and positive values scroll down that amount.
 */
const postScrollBy = (xScrollValue, yScrollValue) => {
	if (xScrollValue || yScrollValue) {
		const scrollByPayload = {
			name: "MDScrollBy",
			xScrollValue: xScrollValue,
			yScrollValue: yScrollValue,
		};
		const postMessage = JSON.stringify(scrollByPayload);
		window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN); // NOSONAR

		// Need to catch cross-origin frame error when in non-integrated env
		try {
			if (window?.MD?.INTEGRATED_DOMAINS?.includes(window?.MD?.PARENT_DOMAIN)) {
				return;
			} else {
				if (window.console) {
					window.scrollBy(xScrollValue, yScrollValue);
					console.info("Works only in integrated environments!");
				}
			}
		} catch (e) {
			if (window.console) {
				window.scrollBy(xScrollValue, yScrollValue);
				console.log(e);
				console.info("Works only in integrated environments!");
			}
		}
	}
};

//Utility for sending a post message to the parent frame to have the parent scroll to the top of the page.
const postScrollTop = () => {
	const scrollTopPayload = {
		name: "MDScrollTop",
	};
	const postMessage = JSON.stringify(scrollTopPayload);
	window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN); // NOSONAR

	// Need to catch cross-origin frame error when in non-integrated env
	try {
		if (window?.MD?.INTEGRATED_DOMAINS.includes(window?.MD?.PARENT_DOMAIN)) {
			console.info("Works only in integrated environments!");
			return;
		} else {
			if (window.console) {
				window.scrollTo(0, 0);
				console.info("Works only in integrated environments!");
			}
		}
	} catch (e) {
		if (window.console) {
			window.scrollTo(0, 0);
			console.log(e);
			console.info("Works only in integrated environments!");
		}
	}
};

const isResponseFailed = (response) => {
	return response.status === "rejected" || !response.data || !response.data.data;
};

const postMSVendorModalScript = (type, id, payload) => {
	if (!type || payload) {
		return;
	}

	if (type === "open" && id) {
		postMSVendorOpenModalScript(id, payload);
		return;
	}

	if (type === "show") {
		postMSVendorShowModalScript(payload);
	}
};

export const postMSVendorRedirectToScript = (payload) => {
	const postMessage = {
		name: "RedirectTo",
		data: payload,
	};
	if (IsIntegratedEnv()) {
		window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN);
	} else {
		if (window.console) {
			console.info("Works only in MS integrated environment!");
		}
	}
};

const postMSVendorShowModalScript = (payload) => {
	const postMessage = {
		name: "OpenModal",
		data: payload,
	};
	if (IsIntegratedEnv()) {
		return window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN);
	} else {
		if (window.console) {
			console.info("Works only in MS integrated environment!");
		}
	}
};

const IsIntegratedEnv = () => {
	//checking is this content hosted in MSO side or not
	try {
		var parent = window.parent.location.href; // Added intentionally to fail.
		if (parent.indexOf("markets-") === -1 && parent.indexOf("markets.") === -1)
			// If not fail then checking second level.
			return true; //Integrated true- Market content is hosted in MSO Iframe.

		return false; //Integrated false - Market content isn't hosted in MSO Iframe.
	} catch (e) {
		return true; //Integrated true- Market content is hosted in MSO Iframe.
	}
};
const postMSVendorOpenModalScript = (definitionId, payload) => {
	if (window.parent.MSORShell) {
		window.parent.MSORShell.OpenModal(payload);
	} else {
		if (window.console) {
			console.info(
				"Works only in MS integrated environment - DefinitionID that will be passed to MS is: " +
					definitionId
			);
		}
	}
};

const getQuoteMarketCapValue = (quoteMarketCap) => {
	const trillion = 1e12;
	const billion = 1e9;
	const million = 1e6;
	if (!quoteMarketCap) {
		return 0;
	}

	if (quoteMarketCap >= trillion) {
		const formatValue = formatter.number(quoteMarketCap / trillion, 3);
		return `$${formatValue}T`;
	}

	if (quoteMarketCap >= billion) {
		const formatValue = formatter.number(quoteMarketCap / billion, 3);
		return `$${formatValue}B`;
	}

	if (quoteMarketCap >= million) {
		const formatValue = formatter.number(quoteMarketCap / million, 3);
		return `$${formatValue}M`;
	}
};

//Method to return K,M,B,T values for large values
const getVolumeHoverValue = (volume) => {
	const trillion = 1e12;
	const billion = 1e9;
	const million = 1e6;
	const thousand = 1e3;
	if (!volume) {
		return `0`;
	}

	if (volume >= trillion) {
		const formatValue = formatter.number(volume / trillion, 2);
		return `${formatValue}T`;
	}

	if (volume >= billion) {
		const formatValue = formatter.number(volume / billion, 2);
		return `${formatValue}B`;
	}

	if (volume >= million) {
		const formatValue = formatter.number(volume / million, 2);
		return `${formatValue}M`;
	}

	if (volume >= thousand) {
		const formatValue = formatter.number(volume / thousand, 2);
		return `${formatValue}K`;
	}
};

export const getFormattedNumber = (value) => {
	const thousand = 1e3;
	if (!value) {
		return `0`;
	}
	if (value > 0 && value < thousand) {
		const formatValue = formatter.number(value, 2);
		return `${formatValue}`;
	} else if (value < 0) {
		return getNegativeFormatedNumber(value);
	} else {
		return getVolumeHoverValue(value);
	}
};

const getNegativeFormatedNumber = (value) => {
	const trillion = -1e12;
	const billion = -1e9;
	const million = -1e6;
	const thousand = -1e3;
	if (!value) {
		return `0`;
	}

	if (value <= trillion) {
		const formatValue = formatter.number(value / trillion, 2);
		return `-${formatValue}T`;
	}

	if (value <= billion) {
		const formatValue = formatter.number(value / billion, 2);
		return `-${formatValue}B`;
	}

	if (value <= million) {
		const formatValue = formatter.number(value / million, 2);
		return `-${formatValue}M`;
	}

	if (value <= thousand) {
		const formatValue = formatter.number(value / thousand, 2);
		return `-${formatValue}K`;
	}
	const formatValue = formatter.number(value, 2);
	return `${formatValue}`;
};

const replaceQueryParam = (paramName, value) => {
	if (!paramName || !value) {
		return "";
	}

	const queryParams = window.location.search;
	const formattedParams = queryParams.replace("?", "");

	const paramsArray = formattedParams.split("&");
	const finalParams = [];
	const newParam = `${paramName}=${value}`;
	finalParams.push(newParam);

	for (const params of paramsArray) {
		const paramKeyValue = params.split("=");
		if (paramKeyValue[0] && paramKeyValue[0].toLowerCase() !== paramName.toLowerCase()) {
			finalParams.push(params);
		}
	}

	const finalParamString = finalParams.join("&");
	return `?${finalParamString}`;
};

const adobeEventTracking = (eventName, pageName, eventType) => {
	if (window.MD && window.MktrsPageData && window._satellite && window.MD.ENV) {
		if (window.MD.ENV !== "localhost") {
			let pageNameText = "";
			switch (eventType) {
				case "page":
					switch (pageName) {
						case "analystresearch":
							pageNameText = `market research | quote search | analyst research-new`;
							window.MktrsPageData.L3 = "analyst research-new";
							break;
						default:
							pageNameText = `market research | quote search | ${pageName}-new`;
							window.MktrsPageData.L3 = `${pageName}-new`;
							break;
					}

					window.MktrsPageData.EventName = eventName;
					window.MktrsPageData.PageName = pageNameText;
					window._satellite.track("MktRS-Page-Tracking");
					break;
				case "event":
					pageNameText = pageName;
					window.MktrsPageData.EventName = eventName;
					window.MktrsPageData.PageName = pageNameText;
					window._satellite.track("MktRS-Event-Tracking");
					break;
				default:
					break;
			}
		}
	}
};

const replaceSymbolInUrl = (symbol, tab = "Overview") => {
	const search = new URLSearchParams(window.location.search);
	search.set("symbol", symbol);
	return `${tab}?${search.toString()}`;
};

const toolTipTrigger = () => {
	const isMobile = window?.matchMedia("max-width: 577px")?.matches;
	return isMobile ? ["focus", "click"] : ["focus", "hover"];
};

export const isRealtimeNasdaqUser = (userInfo) => {
	return (
		!!userInfo?.userQuotePreference &&
		!!userInfo?.userProfessionalStatus &&
		!!userInfo?.userTier &&
		USER_TIERS.NASDAQ_REALTIME.indexOf(userInfo?.userTier) >= 0
	);
};

export const isRealtimeOptionsUser = (userInfo) =>
	userInfo?.userQuotePreference &&
	userInfo?.userProfessionalStatus &&
	userInfo?.userTier &&
	USER_TIERS.OPTIONS_REALTIME.includes(userInfo.userTier);

export const buildTooltip = (
	tooltipId,
	tooltipText,
	tooltipType,
	tooltipHTML,
	placement
) => {
	let tooltipHoverElement;

	switch (tooltipType) {
		case "button":
			tooltipHoverElement = (
				<button
					className="btn btn-link p-0"
					aria-label="tool tip"
					aria-describedby={tooltipId}
				>
					<QuestionIcon aria-hidden={true} className="pl-1"></QuestionIcon>
				</button>
			);
			break;
		case "text":
			tooltipHoverElement = tooltipHTML;
			break;
		default:
			break;
	}

	return (
		<OverlayTrigger
			placement={placement ? placement : "auto"}
			type="question"
			overlay={
				<Tooltip
					role="tooltip"
					id={tooltipId}
					aria-hidden={true}
					aria-label={tooltipText}
				>
					{tooltipText}
				</Tooltip>
			}
			trigger={toolTipTrigger()}
		>
			<span>{tooltipHoverElement}</span>
		</OverlayTrigger>
	);
};

const checkResearchDocumentFormat = (e, productId, documentId, userInfo) => {
	e.preventDefault();

	// only send a postmessage if the user is flagged apprropriately
	// (morgan stanley will utilize this flag to route users to an HTML version of the research document)
	if (userInfo && userInfo.researchRedesignFlag === "Y") {
		// PostMessage Block (allows MS to render a pdf or html document)
		const postMessage = {
			name: "OpenResearchArticle",
			data: {
				ArticleID: productId,
				ResearchPDF: `${window.MD.MSO_DOMAIN}/ms-research/markets-and-research/ResearchPdf?documentId=${documentId}`,
			},
		};
		window.parent.postMessage(postMessage, window.MD.PARENT_DOMAIN); // NOSONAR
	} else {
		// open the pdf version of the research doc
		window.open(
			`${window.MD.MSO_DOMAIN}/ms-research/markets-and-research/ResearchPdf?documentId=${documentId}`
		);
	}
};

const getQuoteURLBasedOnIssueType = (issueType, queryParams) => {
	let url = "";
	switch (issueType.toLowerCase()) {
		case "fund":
			url = `${window.location.origin}/MSOnline/MutualFunds/Overview${queryParams}`;
			break;
		case "etf":
		case "closed end funds":
		case "debt":
			url = `${window.location.origin}/MSOnline/ETFs/Overview${queryParams}`;
			break;
		case "index":
			url = `${window.location.origin}/MSOnline/Indexes/Chart${queryParams}`;
			break;
		default:
			url = `/ms-research/markets-and-research/Stocks/Overview${queryParams}`;
			break;
	}
	return url;
};

const isPropUpdated = (prevProps, currentProps, verificationProp) => {
	if (!prevProps) {
		return false;
	}

	return prevProps[verificationProp] !== currentProps[verificationProp];
};

// Are these all valid returns from the API?
const ratingString = (ratingValue) =>
	["Equal-Weight", "Equal-weight", "Equal weight", "Equal Weight"].includes(ratingValue)
		? "Equal-weight"
		: ratingValue;

const filterOutSpinOffs = (dividendEvents) => {
	return dividendEvents?.filter((o) => o.typeDescription !== SPIN_OFF);
};

const getUniqueDividendCurrency = (data) => {
	let uniqueTypeDescription = [];
	let uniquePaymentCurrency = [];
	if (data?.dividendEvents?.length) {
		uniqueTypeDescription = [
			...new Set(data.dividendEvents.map((x) => x.typeDescription)),
		];

		uniquePaymentCurrency = [
			...new Set(data.dividendEvents.map((x) => x.paymentCurrency)),
		];
	}

	return (
		uniqueTypeDescription.length > 0 &&
		uniquePaymentCurrency.length > 0 &&
		!uniqueTypeDescription.includes("Stock") &&
		uniquePaymentCurrency.length <= 1
	);
};

const getUniqueCurrencies = (currencies) => {
	return currencies ? Array.from(new Set(currencies)) : [];
};

const fetchUniqueCurrency = (currencies) => {
	let uniqueCurrency = getUniqueCurrencies(currencies);
	return uniqueCurrency.length === 1 && uniqueCurrency[0] !== "USD"
		? "CURRENCY: " + uniqueCurrency[0]
		: "";
};

const getDsnByEnvironment = () => {
	switch (window.MD && window.MD.ENV && window.MD.ENV.toLowerCase()) {
		case "production":
			return "https://25eca2aed0f34958881c7b6b20fbfe50@logging-api.markitdigital.com/11";
		case "acceptance":
		case "acceptance-2":
		case "development":
		case "localhost":
		default:
			return "https://553580950ba247c8aa8979131862a1de@logging-api.markitqa.com/16";
	}
};

const utils = {
	adobeEventTracking,
	buildTooltip,
	cloneObject,
	fetchToken,
	getCookieValue,
	getGoodFridayDateFromYear,
	getObjectPropertyValue,
	getQuoteMarketCapValue,
	getVolumeHoverValue,
	isBankHoliday,
	isDateOrDateObserved,
	isPropUpdated,
	isRealtimeNasdaqUser,
	isResponseFailed,
	numBusinessDaysBetweenDates,
	parseXML,
	parseXMLNewsWireBody,
	postDimensions,
	postScrollTop,
	postScrollBy,
	postMSVendorModalScript,
	postMSVendorOpenModalScript,
	postMSVendorRedirectToScript,
	postMSVendorShowModalScript,
	replaceQueryParam,
	replaceSymbolInUrl,
	toolTipTrigger,
	truncateText,
	checkResearchDocumentFormat,
	getQuoteURLBasedOnIssueType,
	getFormattedNumber,
	ratingString,
	filterOutSpinOffs,
	getUniqueDividendCurrency,
	getUniqueCurrencies: getUniqueCurrencies,
	fetchUniqueCurrency,
	getDsnByEnvironment,
	IsIntegratedEnv,
};
export default utils;
