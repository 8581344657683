import React, {
	useEffect,
	useState,
	createContext,
	useRef,
	useLayoutEffect,
	useMemo,
} from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import TagManager from "react-gtm-module";
import Utils from "utils/utils";
import { CLIENTS, CANACCORD_GENUITY_BACKGROUND } from "utils/constants";
import Options from "./Options/Options";
import MarketsAndResearchApp from "./MarketsAndResearch/MarketsAndResearchApp";
import CanacordResearchApp from "./Canacord/Research/ResearchApp";
import RequestService from "../services/RequestService";
import SecFilingsPdf from "./MarketsAndResearch/Overview/SecFilingsPdf/SecFilingsPdf";
import ResearchPdf from "./MarketsAndResearch/AnalystResearch/AllResearchCard/ResearchPdf/ResearchPdf";
import { getUserInfo } from "services/APIs/UserInfo/UserInfoAPI";
import "./App.scss";
import { QuoteErrorMessage } from "./MarketsAndResearch/Error/QuoteSearchError/ErrorMessage";
import * as Sentry from "@sentry/browser";
Sentry.init({
	dsn: Utils.getDsnByEnvironment(),
	environment: window.MD.ENV,
});

const initializeGoogleAnalytics = () => {
	TagManager.initialize({
		gtmId: "GTM-WK9LVRV",
		auth: window.MD?.GTM_AUTH || "",
		preview: window.MD?.GTM_PREVIEW || "",
	});
};
const populateGlobals = () => {
	const parentOrigin =
		window.location.ancestorOrigins?.length > 0
			? window.location.ancestorOrigins[0]
			: document.referrer;
	const iFrameDomain = window.location.origin;
	window.MD.MS_DOMAINS.forEach((item) => {
		const regEx = new RegExp(item);
		if (regEx.test(parentOrigin)) {
			window.MD.PARENT_DOMAIN = item;
		}
		if (regEx.test(iFrameDomain)) {
			window.MD.MSO_DOMAIN = item;
			window.MD.CLIENT = CLIENTS.MORGAN_STANLEY;
		}
	});
	if (!window.MD.CG_API_DOMAIN && window.MD.CG_SUB_DOMAINS?.length) {
		window.MD.CG_SUB_DOMAINS.forEach((item) => {
			const regEx = new RegExp(item);
			if (regEx.test(iFrameDomain)) {
				window.MD.CG_API_DOMAIN = item;
				window.MD.CLIENT = CLIENTS.CANACCORD_GENUITY;
			}
		});
	}

	// Get the client from the iframe query param
	// on localhost, since we can't determine the client by the domain
	const query = new URLSearchParams(window.location.search);
	const client = query.get("client");
	const pathname = window.location.pathname.split("/")[1];

	// Only set the client this way if it is passed in the query string
	// and it hasn't already been set from the domain/globals.js setup
	if (client && !window.MD.CLIENT) {
		window.MD.CLIENT =
			client === CLIENTS.CANACCORD_GENUITY
				? CLIENTS.CANACCORD_GENUITY
				: CLIENTS.MORGAN_STANLEY;
	}
	if (pathname === "ms-research") {
		initializeGoogleAnalytics();
	}
};
export const ApiInstanceAndUserInfoContext = createContext();

const App = React.memo(() => {
	const [authToken, setAuthToken] = useState();
	const apiInstance = useMemo(() => {
		populateGlobals();
		return RequestService.init(authToken);
	}, [authToken]);
	const [symbol, setSymbol] = useState(null);
	const [viewType, setViewType] = useState(null);
	const [optionsType, setOptionsType] = useState(null);
	const [isOptionsTradeEnabled, setIsOptionsTradeEnabled] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [frameSize, setFrameSize] = useState({ height: null, width: null });
	const [resizeObserver] = useState(
		new ResizeObserver((entries) => {
			window.requestAnimationFrame(() => {
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				updateFrameSizes(entries);
			});
		})
	);
	const [analyticsIsInitialized, setAnalyticsIsInitialized] = useState(false);
	const mainRef = useRef();
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: 2,
			},
		},
	});

	useEffect(() => {
		if (window.location.pathname.split("/")[1] !== "ms-research") return;
		const headScript = document.createElement("script");
		const initializeAdobeAnalytics = () => {
			const { head } = document;
			headScript.setAttribute("type", "text/javascript");
			window.MktrsPageData = {
				EventName: "",
				Site: "mso",
				Platform: "",
				ClientUUID47: "",
			};
			if (window.location.pathname.split("/")[2] === "options") {
				window.MktrsPageData = {
					...window.MktrsPageData,
					PageName: "trade | enter trade | options chain",
					L1: "trade",
					L2: "enter trade",
					L3: "options chain",
				};
			} else {
				window.MktrsPageData = {
					...window.MktrsPageData,
					PageName: "market research | quote search | overview-new",
					L1: "market research",
					L2: "quote search",
					L3: "overview-new",
				};
			}

			switch (window.MD.ENV) {
				case "production":
					headScript.src =
						"//assets.adobedtm.com/b124caa02ab9/58769afc0174/launch-0afb1da30e25.min.js";
					break;
				case "acceptance-2":
				case "acceptance":
				case "development":
					headScript.src =
						"//assets.adobedtm.com/b124caa02ab9/58769afc0174/launch-c1ac571f714c-development.min.js";
					break;
				default:
					break;
			}
			head.appendChild(headScript);
			headScript.onload = () => {
				setAnalyticsIsInitialized(true);
			};
		};
		initializeAdobeAnalytics();
		return () => {
			headScript.removeEventListener("load", setAnalyticsIsInitialized);
		};
	}, []);
	const handleMessage = (event) => {
		if (!event?.data || typeof event.data !== "string") return;
		if (
			event.origin !== window.MD.PARENT_DOMAIN &&
			event.origin !== window.MD.CG_API_DOMAIN
		)
			return;
		const { name, data } = JSON.parse(event.data);
		if ((name !== "LoadOptions" && name !== "LoadInternalStorybook") || !data) return;
		setSymbol(data.symbol);
		setViewType(data.viewType);
		setOptionsType(data.optionsType);
		setIsOptionsTradeEnabled(data.isOptionsTradeEnabled);
		if (data.isInternal) setAuthToken(data.authToken);
	};

	useEffect(() => {
		window.parent.postMessage(
			JSON.stringify({ name: "MDFrameLoaded" }),
			window.MD.PARENT_DOMAIN
		);
		if (window?.MD?.CLIENT === CLIENTS.CANACCORD_GENUITY) {
			document.body.parentElement.style.backgroundColor = CANACCORD_GENUITY_BACKGROUND;
		}
	}, []);
	useEffect(() => {
		window.addEventListener("message", handleMessage, []);
		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);
	useEffect(() => {
		const getUserInfoData = async () => {
			if (window?.MD?.CLIENT !== CLIENTS.MORGAN_STANLEY) return;
			const userInfoData = await getUserInfo(apiInstance);
			setUserInfo(userInfoData);
		};
		getUserInfoData();
	}, [apiInstance]);

	useEffect(() => {
		if (userInfo?.channel && userInfo?.uuid && analyticsIsInitialized) {
			window.MktrsPageData.Platform =
				userInfo.channel.toLowerCase() === "msonline" ? "website" : "mobile";
			window.MktrsPageData.ClientUUID47 = userInfo.uuid;

			//setting pageBottom() script dynamically to allow for page to load
			const bodyScript = document.createElement("script");
			bodyScript.setAttribute("type", "text/javascript");
			bodyScript.innerHTML = `window._satellite.pageBottom();`;
			document.body.appendChild(bodyScript);

			//event tracking even on page load
			window._satellite.track("MktRS-Page-Tracking");
		}
	}, [userInfo, analyticsIsInitialized]);
	useLayoutEffect(() => {
		const currentRef = mainRef.current;
		resizeObserver.observe(currentRef);
		return () => {
			resizeObserver?.unobserve(currentRef);
		};
	}, [resizeObserver, mainRef]);
	const updateFrameSizes = (entries) => {
		if (entries?.length && entries[0].contentRect) {
			const { height: newHeight, width: newWidth } = entries[0].contentRect;
			if (frameSize.height === newHeight && frameSize.width === newWidth) return;
			Utils.postDimensions(newHeight, newWidth);
			setFrameSize({ height: newHeight, width: newWidth });
		}
	};
	return (
		<QueryClientProvider client={queryClient}>
			<ApiInstanceAndUserInfoContext.Provider value={{ apiInstance, userInfo }}>
				<BrowserRouter>
					<section
						ref={mainRef}
						aria-label="main"
						className="container"
						style={{ maxWidth: "none", padding: "0" }}
					>
						<div className="content">
							<Route
								path="/"
								exact
								render={() => (
									<div>
										<h1> Markit Digital</h1> <p>Options Test Page!</p>{" "}
									</div>
								)}
							/>
							<Switch>
								{/* Canacord Genuity Routes */}
								<Route
									path="/cg-research/research"
									exact
									render={(props) => (
										<CanacordResearchApp {...props} apiInstance={apiInstance} />
									)}
								/>
								{/* Morgan Stanley Routes */}
								<Route
									path="/ms-research/options"
									exact
									render={(props) => (
										<Options
											{...props}
											optionsData={{
												symbol,
												viewType,
												optionType: optionsType,
												isOptionsTradeEnabled,
											}}
											userInfo={userInfo}
											apiInstance={apiInstance}
										/>
									)}
								/>
								<Route
									path="/ms-research/markets-and-research/SecFilingsPdf"
									exact
									render={() => <SecFilingsPdf apiInstance={apiInstance} />}
								/>
								<Route
									path="/ms-research/markets-and-research/Stocks/QuoteError"
									render={(props) => <QuoteErrorMessage {...props} />}
								/>
								<Route
									path={[
										"/ms-research/markets-and-research/ResearchPdf",
										"/cg-research/ResearchPdf",
									]}
									render={() => <ResearchPdf apiInstance={apiInstance} />}
								/>
								<Route
									path="/ms-research/markets-and-research/:product/:name/:tab?"
									render={(props) => (
										<MarketsAndResearchApp
											{...props}
											queryClient={queryClient}
											apiInstance={apiInstance}
										/>
									)}
								/>
								<Route
									path="/ms-research/markets-and-research/:name"
									render={(props) => (
										<MarketsAndResearchApp {...props} queryClient={queryClient} />
									)}
								/>
							</Switch>
						</div>
					</section>
				</BrowserRouter>
			</ApiInstanceAndUserInfoContext.Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
});

export default App;
