// Default to all feature flags turned off for Production
let FEATURE_FLAGS = {
	LIST_ALL_INDICATORS: false,
	MSQR_7541_BORDER_COLOR: false,
	MSQR_8100_REFRESH_BUTTON: false,
};

// Conditionally turn on features per environment
// If flags are set to true for all environments, then we can look to decommission the flag
if (window.MD && window.MD.ENV) {
	switch (window.MD.ENV) {
		case "acceptance":
		case "acceptance-2":
		case "development":
		case "localhost":
			FEATURE_FLAGS = {
				...FEATURE_FLAGS,
				LIST_ALL_INDICATORS: true,
			};
			break;
		case "production":
		default:
			FEATURE_FLAGS = {
				...FEATURE_FLAGS,
			};
			break;
	}
}

export default FEATURE_FLAGS;
